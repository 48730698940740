import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { _animationHoverButton_Border, _animationHoverButton_Text, _animationHoverButton_Background } from '@/libraries/animation'
export const CustomButton = (props) => {
    const {
        text = "Know More",
        borderColorStart = "var(--primaryColor)",
        borderColorEnd = "transparent",
        href = null //Add a href prop to the CustomButton
    } = props;

    const router = useRouter();

    const handleNavigation = () => {
        if (href) {
            router.push(`/${router.locale}${href}`);
        }
    }

    return (
        <motion.button 
            className={'custom_button'}
            initial="visible"
            whileHover="hover"
            whileTap="tap"
            onClick={handleNavigation}
        >
            <motion.div 
                className='custom_button_border'
                variants={_animationHoverButton_Border({borderColorStart: borderColorStart, borderColorEnd: borderColorEnd})}
            >
                <motion.div 
                    className='custom_button_text color_secondary'
                    variants={_animationHoverButton_Text()}
                >
                    {text}
                </motion.div>
            </motion.div>
            <motion.div 
                className='custom_button_background_position'
                variants={_animationHoverButton_Background()}
            />
        </motion.button>
    )
  }