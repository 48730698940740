
import React from 'react';
import { Link } from "@/helpers/common_helper";
import { useState, useEffect } from "react";
import { useRouter } from 'next/router';
import _ from 'lodash';
import { LoaderFull } from '@/components/loader';
import { Image } from '@mantine/core';
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { reduce } from 'lodash';

const headerMenu = [
    {name: "home", urlLink: ''},
    {name: "about", urlLink: 'about'},
    {name: "service", urlLink: 'service'},
    // {name: "portfolio", urlLink: 'portfolio'},
    {name: "contact", urlLink: 'contact'}
]

export const PageHeader = ({ title, ...props }) => {
    const { session, t } = props;
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
        <>
            <div className='container'>
                <Link href={`/`}>
                    <Image
                        className='header_logo'
                        height={43}
                        width={160}
                        src="/assets/images/logo.png"
                        alt="Logo"
                        withPlaceholder
                    />
                </Link>
            </div>
            <div onClick={()=>{setIsMenuOpen(true)}} className='mobile_header'>
                <FiMenu className='color_primary' size={28} />
            </div>
            {isMenuOpen &&
                <div className='mobile_header_box'>
                    <div className='mobile_header_box_menu_side'>
                        <div className='container'>
                            <Link href={`/`}>
                                <Image
                                    className='header_logo'
                                    height={43}
                                    width={160}
                                    src="/assets/images/logo.png"
                                    alt="Logo"
                                    withPlaceholder
                                />
                            </Link>
                        </div>
                        <div className='mobile_header_box_menu_side_list'>
                        {
                            reduce(headerMenu, (result, value, key) => {
                                result.push(
                                    <Link key={key} href={`/` + value.urlLink}>
                                        <div
                                            className='header_right_box_item'
                                        >
                                            <span 
                                                className='header_menu_text'
                                            >
                                                {value.name}
                                            </span>
                                        </div>
                                    </Link>
                                )
                                return result;
                            }, [])
                        }
                        </div>
                    </div>
                    <div className='mobile_header_box_close_side'>
                        <div onClick={()=>{setIsMenuOpen(false)}} className='mobile_header_box_close'>
                            <IoClose className='color_secondary' size={28} />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}