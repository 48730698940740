export const _animationLeftToRight = ({duration = 0.5}) => {
    const obj = {
        visible: {
            x: "0", 
            opacity: 1,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
        hidden: {
            x: "-100%", 
            opacity: 0
        },
        hover: {
            scale: 1.2
        },
        tap: {
            scale: 0.9
        }
    }
    return obj;
}

export const _animationRightToLeft = ({duration = 0.5}) => {
    const obj = {
        visible: {
            x: "0", 
            opacity: 1,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
        hidden: {
            x: "100%", 
            opacity: 0
        },
    }
    return obj;
}

export const _animationBottomToTop = ({duration = 0.5}) => {
    const obj = {
        visible: {
            y: "0", 
            opacity: 1,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
        hidden: {
            y: "100%", 
            opacity: 0
        },
    }
    return obj;
}

export const _animationGoTop = ({duration = 0.5}) => {
    const obj = {
        hover: {
            y: "-50px", 
            scale: 1.2,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        }
    }
    return obj;
}
export const _animationGoTop2 = ({duration = 0.5}) => {
    const obj = {
        hover: {
            y: "-40px", 
            scale: 1.15,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        }
    }
    return obj;
}

export const _animationFormBottomLeft = ({duration = 0.5}) => {
    const obj = {
        visible: {
            y: "0",
            x: "0", 
            opacity: 1,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
        hidden: {
            y: "100px", 
            x: "-100%",
            opacity: 0
        },
    }
    return obj;
}

export const _animationFormBottomRight = ({duration = 0.5}) => {
    const obj = {
        visible: {
            y: "0",
            x: "0", 
            opacity: 1,
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
        hidden: {
            y: "100px", 
            x: "100%",
            opacity: 0
        },
    }
    return obj;
}


export const _animationHoverButton_Background = () => {
    const obj = {
        visible: {
            y: 10,
            x: 10,
            backgroundColor: 'var(--thirdColor)'
        },
        hover: {
            y: 0,
            x: 0,
            backgroundColor: 'var(--fourthColor)'
        },
        tap: {
            scale: 0.9,
        }
    }
    return obj;
}
export const _animationHoverButton_Border = ({borderColorStart, borderColorEnd}) => {
    const obj = {
        visible: {
            borderColor: borderColorStart
        },
        hover: {
            borderColor: borderColorEnd
        },
    }
    return obj;
}
export const _animationHoverButton_Text = () => {
    const obj = {
        visible: {
            color: 'var(--primaryColor)'
        },
        hover: {
            color: 'var(--secondaryColor)'
        },
        tap: {
            scale: 0.9,
        }
    }
    return obj;
}

export const _animationHoverGetText_Image = () => {
    const obj = {
        visible: {
            scale: 1,
            opacity: 1
        },
        hover: {
            scale: 0,
            opacity: 0
        },
    }
    return obj
}

export const _animationHoverGetText_Background = () => {
    const obj = {
        visible: {
            y: 10,
            x: 10,
            backgroundColor: 'var(--fourthColor)'
        },
        hover: {
            y: 0,
            x: 0,
            backgroundColor: 'var(--thirdColor)'
        },
    }
    return obj
}

export const _animationHoverGetText_Text = () => {
    const obj = {
        visible: {
            scale: 0,
            opacity: 0,
            display: "none"
        },
        hover: {
            scale: 1,
            opacity: 1,
            transitionEnd: { display: "flex" }
        },
    }
    return obj
}

export const _animationHoverImageGrayscale = ({duration = 0.5}) => {
    const obj = {
        visible: {
            filter: "grayscale(100%)",
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
        hover: {
            filter: "grayscale(0%)",
            transition: {
                type: 'spring',
                duration: duration,
                bounce: 0
            }
        },
    }
    return obj
}