import Footer from "@/components/mobile/layout/footer"
import { PageHeader } from "@/components/mobile/layout/header"

export const PageLayout = ({ children, title = "", page = "", ...props }) => {

    return <>
        <PageHeader title={title} {...props}/>
        <div>
            {children}
            <Footer {...props}/>
        </div>
    </>;
}