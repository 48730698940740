import React from "react";
import { Loader as L, LoadingOverlay as LoadingO} from "@mantine/core";
import { theme } from "@/config/site";

export const LoaderCenter = ({ style={}, ...props }) => {
    return <div className="loader-dots" style={{...style}}>
        <L variant="dots" {...props} />
    </div>
}

export const LoaderFull = ({ visible=true }) => {
    
    return <div style={ visible ? { display: "show" } : { display: "none" } }>            
        <div className="loader-wrapper-main">
            <div className="loader-wrapper">
                <L variant="dots"/>
            </div>
        </div>
    </div>
}

export const LoadingOverlay = ({ visible=true, zIndex=1000 }) => {
    return <LoadingO visible={visible} zIndex={zIndex} style={{ position: 'fixed'}}/>
}
